import { useQuery } from "react-query";

import { getCombinedCustomTopStats } from "@/api/sessions";

import ContentLoader from "@/components/content-loader/content-loader";

import useQueryParams from "@/utils/use-query-params";

import { CustomTopStat } from "./top-stat";
import { TopStatWrapper } from "./top-stats-styles";

import type { TopStatProps } from "./top-stat";

type StatItem = {
    value: Array<{ result: number | string; unit: string; title: string; transform?: string }>;
    position: number;
    legend: string | null;
    code: string;
    description: string;
};

// util so that objects with the same code are combined
const combineStatsByCode = (data: StatItem[]): StatItem[] => {
    const combinedStats: Record<string, StatItem> = {};

    data.forEach((item) => {
        if (combinedStats[item.code]) {
            // merge values if exists
            combinedStats[item.code].value = [...combinedStats[item.code].value, ...item.value];
        } else {
            combinedStats[item.code] = { ...item };
        }
    });

    return Object.values(combinedStats);
};

const CustomTopStats = () => {
    const query: any = useQueryParams();

    const scenarioInstanceId = query.get("scenarioInstanceId");
    const simulationInstanceId = query.get("simulationInstanceId");
    const userId = query.get("userId");
    const from = query.get("from");
    const to = query.get("to");

    const { isFetching: isLoading, data } = useQuery(
        ["getCombinedCustomTopStats", userId, from, to, simulationInstanceId, scenarioInstanceId],
        () =>
            getCombinedCustomTopStats({
                from,
                to,
                userId,
                simulationInstanceId,
                scenarioInstanceId,
            }),
        {
            suspense: false,
            refetchOnMount: false,
            useErrorBoundary: true,
        },
    );

    if (isLoading) return <ContentLoader height="10.125rem" />;

    if (!data) return null;

    // combine stats with the same code even if from different sources
    const combinedData = combineStatsByCode(data);
    const topStatsData = combinedData.sort(
        (stat1: TopStatProps, stat2: TopStatProps) => stat1.position - stat2.position,
    );

    return (
        <TopStatWrapper>
            {topStatsData.map((stat: TopStatProps, index: number) => (
                <CustomTopStat {...stat} key={`${stat.code}-${index}`} />
            ))}
        </TopStatWrapper>
    );
};

export default CustomTopStats;
