import { useEffect, useState, useRef } from "react";
import { FormattedMessage } from "react-intl";
import { TileLayer, Marker, Polyline, MapContainer as LeafletMapContainer } from "react-leaflet";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";

import "leaflet/dist/leaflet.css";

import L from "leaflet";
import type { LatLngTuple } from "leaflet";
import styled from "styled-components";

import { getMapData } from "@/api/sessions";

import { useModal } from "@/context/modal/modal";

import ContentLoader from "@/components/content-loader/content-loader";
import EmptyState from "@/components/empty-state/empty-state";
import { EmptyStateType } from "@/components/empty-state/empty-state.types";
import { ExpandDiagonalIcon24 } from "@/components/icons";
import MapTimelineModal from "@/components/ui/modal-with-context/modal-contents/map-timeline/map-timeline-modal-content";

import type { ErrorWithMessage, MovementSegment } from "@/types/session-details";

import { Title } from "../../session-detail.styles";

import Controls from "../cockpit-timeline/controls";

import MapEffect from "./components/map-effect";
import MapObserver from "./components/map-observer";
import SpeedControl from "./components/speed-control";
import { useMapDataTimeline } from "./components/useMapDataTimeline";

import { HeaderContainer, FullScreenDiv, ExpandIconContainer, Wrapper } from "./styles";

//Currently on the free plan but once this is paid the access token and URL should come from BE.
const accessToken =
    "pk.eyJ1IjoibGF1cmF2cmFpIiwiYSI6ImNsdm1td3pyczAzdHQyaW0wenh1amtmdWwifQ.RGG_jsrFUrOGYrj0jz7S0g";
const mapboxURL = `https://api.mapbox.com/styles/v1/lauravrai/clvnwqljf00cz01qu7q6lhfk0/tiles/256/{z}/{x}/{y}@2x?access_token=${accessToken}`;

const speedBlockColors = ["#8D2260", "#641844", "#4D4D4D", "#A9501C", "#EE7127"];

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

// Define map settings and constants in a config object
const mapConfig = {
    zoom: undefined as number | undefined,
    minZoom: 5,
    maxZoom: 18,
    maxBounds: [
        [35, -25],
        [70, 40],
    ] as [LatLngTuple, LatLngTuple],
    radius: 5,
    polylineOuterWeight: 12,
    polylineInnerWeight: 10,
    circleFillColor: {
        start: "#81842C",
        end: "#C13D40",
        observation: "#0B5370",
    },
    circleColor: "#f2f2f2",
    circleFillOpacity: 1,
};

const StyledMapContainer = styled(LeafletMapContainer)`
    height: 100%;
    width: 100%;
`;

const averageSpeedWindow = 6;

const makeCoords = (_movementData: any): LatLngTuple => {
    return [parseFloat(_movementData.latitude), parseFloat(_movementData.longitude)] as LatLngTuple;
};

const MapTimeline = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const sessionId = queryParams.get("simulationInstanceId") || "";
    const scenarioInstanceId = queryParams.get("scenarioInstanceId") || "";
    const start = queryParams.get("from") || "";
    const end = queryParams.get("to") || "";

    const [isUsingSpeed, setIsUsingSpeed] = useState<boolean>(true);
    const [minCoords, setMinCoords] = useState<LatLngTuple | null>(null);
    const [maxCoords, setMaxCoords] = useState<LatLngTuple | null>(null);
    const [movementData, setMovementData] = useState<MovementSegment[]>([]);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const { openModal, setWidth, setHeight, isModalOpen } = useModal();
    const positionMarkerRef = useRef<L.Marker | any>(null);
    const previousHeadingRef = useRef<number>(0); // Store previous heading to check for significant movement
    const [rotationAngle, setRotationAngle] = useState<number>(0);
    const rotationThreshold = 5;

    // Define a function to create a custom icon
    const createCustomIcon = (iconUrl: string, size = 25) =>
        new L.Icon({
            iconUrl: iconUrl,
            iconSize: [size, size], // Size of the icon
            iconAnchor: [size / 2, size / 2], // Anchor point of the icon
            popupAnchor: [0, -15], // Where the popup should open relative to the iconAnchor
            rotationAngle: rotationAngle,
        });

    // Use the function to create icons
    const customStartMarkerIcon = createCustomIcon("/assets/start-marker.svg", 40);
    const customEndMarkerIcon = createCustomIcon("/assets/end-marker.svg", 40);
    const customCurrentMarkerIcon = new L.DivIcon({
        className: "rotating-icon",
        html: `<div style="width: 60px; height: 60px;">
       <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 100 150"
    style="transform-origin: 50% 50%; transform: rotate(${rotationAngle}deg) scaleY(-1); width: 60px; height: 60px;"
>
    <path d="M50 20 L70 50 L50 120 L30 50 Z" fill="#0B5370" stroke="white" stroke-width="3" />
</svg>
      </div>`,
        iconSize: [260, 260], // Size of the icon
        iconAnchor: [30, 30], // Anchor point of the icon
        popupAnchor: [0, -15], // Where the popup should open relative to the iconAnchor
    });

    const {
        data: mapData,
        error,
        isFetching: isLoading,
    } = useQuery(
        ["mapData", sessionId, scenarioInstanceId, start, end],
        () => getMapData(sessionId, scenarioInstanceId, 0, 10000),
        {
            // eslint-disable-next-line no-console
            onError: (err: ErrorWithMessage) => console.error(err.message),
        },
    );

    const [isPristine, setIsPristine] = useState(true);

    const {
        currentTick,
        setTick,
        isPlaying,
        play,
        pause,
        durationTimecode,
        currentTimecode,
        totalTickCount,
        currentObservations,
    } = useMapDataTimeline(mapData?.movementData || []);

    //Update the style of the current DOM icon ensuring the anchor
    //is central and keeping the transform3d intact while adding a rotational value.
    const updateMarkerHeading = (newHeading: number) => {
        const headingDifference = Math.abs(newHeading - previousHeadingRef.current);

        if (headingDifference >= rotationThreshold) {
            previousHeadingRef.current = newHeading;

            if (positionMarkerRef.current) {
                setRotationAngle(newHeading);
            }
        }
    };

    useEffect(() => {
        if (currentTick > 0) {
            setIsPristine(false);
        }

        // Re-center the map when currentTick changes
        if (currentObservations?.default) {
            setCurrentCenter({
                coords: currentObservations.default.currentPosition,
                zoom: mapConfig.zoom,
            });
            updateMarkerHeading(currentObservations.default.currentHeading);
        }
    }, [currentTick, currentObservations, mapData]);

    // Modify getColorFromSpeed to calculate average speed from current and previous N speeds
    const getColorFromSpeed = (
        speeds: number[],
        currentIndex: number,
        minSpeed: number,
        maxSpeed: number,
    ) => {
        let sum = 0;
        let count = 0;

        for (let i = Math.max(0, currentIndex - averageSpeedWindow); i <= currentIndex; i++) {
            sum += speeds[i];
            count++;
        }

        const averageSpeed = sum / count;
        const speedRange = maxSpeed - minSpeed;
        const speedIndex = Math.floor(
            ((averageSpeed - minSpeed) / speedRange) * (speedBlockColors.length - 1),
        );

        //Only return the speedblock color if speed is being used.
        //Otherwise return the last index colour of speedBlock.

        return speedBlockColors[isUsingSpeed ? speedIndex : speedBlockColors.length - 1];
    };

    const [currentCenter, setCurrentCenter] = useState<{
        coords: LatLngTuple;
        zoom?: number;
    } | null>(null);

    useEffect(() => {
        if (minCoords && maxCoords && wrapperRef.current) {
            const isValidCoord = (coord: LatLngTuple) =>
                !isNaN(coord[0]) && !isNaN(coord[1]) && isFinite(coord[0]) && isFinite(coord[1]);

            if (isValidCoord(minCoords) && isValidCoord(maxCoords)) {
                const centerLat = (minCoords[0] + maxCoords[0]) / 2;
                const centerLong = (minCoords[1] + maxCoords[1]) / 2;
                const bounds = L.latLngBounds(
                    L.latLng(minCoords[0], minCoords[1]),
                    L.latLng(maxCoords[0], maxCoords[1]),
                );
                const map = L.map(wrapperRef.current).fitBounds(bounds);
                const zoomLevel = map.getZoom();
                map.remove();

                if (zoomLevel <= 0) {
                    setTimeout(() => setMinCoords([...minCoords]), 100);
                } else {
                    setCurrentCenter({
                        coords: [centerLat, centerLong],
                        zoom: zoomLevel,
                    });

                    mapConfig.zoom = zoomLevel;
                }
            }
        }
    }, [minCoords, maxCoords]); // Ensure this effect runs whenever minCoords or maxCoords changes

    useEffect(() => {
        if (mapData?.movementData) {
            const latitudes = mapData.movementData.map((item: any) => parseFloat(item.latitude));
            const longitudes = mapData.movementData.map((item: any) => parseFloat(item.longitude));

            const minLat = Math.min(...latitudes);
            const maxLat = Math.max(...latitudes);
            const minLong = Math.min(...longitudes);
            const maxLong = Math.max(...longitudes);

            setMinCoords([minLat, minLong]);
            setMaxCoords([maxLat, maxLong]);
        }
    }, [mapData]); // Ensure this effect runs whenever mapData changes

    useEffect(() => {
        const speeds = mapData.movementData.map((item: { speed: string }) =>
            parseFloat(item.speed),
        );

        for (const item of mapData.movementData) {
            if (item.speed === "null") {
                setIsUsingSpeed(false);
                break;
            } else {
                setIsUsingSpeed(true);
            }
        }

        // Calculate average speeds for each point considering the last N speeds
        const averageSpeeds = speeds.map((_: any, index: number) => {
            let sum = 0;
            let count = 0;

            for (let i = Math.max(0, index - averageSpeedWindow); i <= index; i++) {
                sum += speeds[i];
                count++;
            }

            return sum / count;
        });

        const minSpeed = Math.min(...averageSpeeds);
        const maxSpeed = Math.max(...averageSpeeds);

        const processedData = mapData?.movementData?.reduce(
            (acc: any, item: any, index: any, array: any) => {
                if (index === 0) return acc;
                const prevItem = array[index - 1];
                const color = getColorFromSpeed(averageSpeeds, index, minSpeed, maxSpeed);
                acc.push({
                    coords: [
                        [parseFloat(prevItem.latitude), parseFloat(prevItem.longitude)],
                        [parseFloat(item.latitude), parseFloat(item.longitude)],
                    ],
                    color: color,
                    speed: speeds[index],
                    heading: item.heading,
                });

                return acc;
            },
            [],
        );
        setMovementData(processedData);
    }, [mapData, averageSpeedWindow, isUsingSpeed]);

    const handleDoubleClick = (coords: LatLngTuple) => {
        return {
            dblclick: () => setCurrentCenter({ coords, zoom: mapConfig.zoom }),
        };
    };

    const startingPoint: any = mapData?.movementData?.length
        ? makeCoords(mapData?.movementData[0])
        : null;
    const endingPoint: any = mapData?.movementData?.length
        ? makeCoords(mapData?.movementData.at(-1))
        : null;

    if (isLoading) return <ContentLoader height="23rem" />;

    if (error) return <div>An error occurred: {error.message}</div>;

    const handleExpandContainerButtonClick = () => {
        setWidth("1100px");
        setHeight("720px");
        openModal({
            content: <MapTimelineModal />,
        });
    };

    return (
        <>
            <Wrapper
                bottomGap={true}
                borderTopRadius={true}
                borderBottomRadius={true}
                isInModal={isModalOpen}
            >
                {movementData?.length > 0 && (
                    <HeaderContainer>
                        <Title>
                            <FormattedMessage id={"session-detail:route"} />
                        </Title>
                        {!isModalOpen && (
                            <ExpandIconContainer onClick={handleExpandContainerButtonClick}>
                                <ExpandDiagonalIcon24 />
                            </ExpandIconContainer>
                        )}
                    </HeaderContainer>
                )}

                <FullScreenDiv renderedInModal={isModalOpen} ref={wrapperRef}>
                    {movementData?.length && mapConfig.zoom ? (
                        <StyledMapContainer
                            center={startingPoint || [0, 0]}
                            zoom={mapConfig.zoom}
                            minZoom={mapConfig.minZoom}
                            maxZoom={mapConfig.maxZoom}
                            maxBounds={mapConfig.maxBounds}
                        >
                            <TileLayer url={mapboxURL} accessToken={accessToken} />
                            {movementData.map((segment, index) => (
                                <Polyline
                                    key={`segment-${index}`}
                                    positions={segment.coords}
                                    color={"#fff"}
                                    weight={mapConfig.polylineOuterWeight}
                                />
                            ))}
                            {movementData.map((segment, index) => (
                                <Polyline
                                    key={`segment-outer-${index}`}
                                    positions={segment.coords}
                                    color={segment.color}
                                    weight={mapConfig.polylineInnerWeight}
                                />
                            ))}
                            {startingPoint && (
                                <Marker
                                    position={startingPoint}
                                    icon={customStartMarkerIcon}
                                    eventHandlers={handleDoubleClick(startingPoint)}
                                />
                            )}
                            {endingPoint && (
                                <Marker
                                    position={endingPoint}
                                    icon={customEndMarkerIcon}
                                    eventHandlers={handleDoubleClick(endingPoint)}
                                />
                            )}
                            {!isPristine && currentObservations?.default && (
                                <Marker
                                    position={currentObservations.default.currentPosition}
                                    icon={customCurrentMarkerIcon}
                                    eventHandlers={handleDoubleClick(
                                        currentObservations.default.currentPosition,
                                    )}
                                    ref={positionMarkerRef}
                                />
                            )}
                            {mapData && currentCenter?.coords && (
                                <MapEffect
                                    centerPosition={{
                                        coords: new L.LatLng(
                                            currentCenter.coords[0],
                                            currentCenter.coords[1],
                                        ),
                                        zoom: currentCenter.zoom,
                                    }}
                                    play={play}
                                    pause={pause}
                                    isPlaying={isPlaying}
                                />
                            )}
                            <MapObserver />
                        </StyledMapContainer>
                    ) : (
                        <EmptyState
                            title={"session-detail:route-map-empty-state-description"}
                            emptyStateType={EmptyStateType.Charts}
                            minHeight="26.6875rem"
                        />
                    )}
                </FullScreenDiv>
                {movementData?.length > 0 && (
                    <>
                        <SpeedControl displaySpeed={isUsingSpeed} />
                        <Controls
                            isDisabled={isLoading || !mapData}
                            isPlaying={isPlaying}
                            play={play}
                            pause={pause}
                            currentTick={currentTick}
                            setTick={setTick}
                            totalTickCount={totalTickCount}
                            currentTime={currentTimecode}
                            duration={durationTimecode}
                        />
                    </>
                )}
            </Wrapper>
        </>
    );
};

export default MapTimeline;
